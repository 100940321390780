// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

div#tableau-container > div {
    width: 100%;
}

.MuiGridListTileBar-subtitle {
    font-size: 1rem !important;
    white-space: break-spaces !important;
}