.container {
  position: relative;
}

.input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.inputFocused {
  outline: none;
}

.inputOpen {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 0 0 4px 4px;
  z-index: 2;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.suggestionHighlighted {
  background-color: #ddd;
}

     .react-autosuggest__container 
        {
            position: relative;
        }
          
        .react-autosuggest__input 
        {
            width:          100%;
            height:         36px;
            padding:        10px;
            border:         1px solid $chefhero-light-green;
            border-radius:  4px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
          
        .react-autosuggest__input--focused 
        {
            outline: none;
        }
          
        .react-autosuggest__input--open 
        {
            border-bottom-left-radius:  0;
            border-bottom-right-radius: 0;
        }
          
        .react-autosuggest__suggestions-container
        {
            display: none;
        }
          
        .react-autosuggest__suggestions-container--open 
        {
            display:            block;
            position:           absolute;
            top:                33px;
            width:              100%;
            min-width:          160px;
            margin-left:        1px;
            background-color: #FFFFFF;
            border-radius:      0 0 4px 4px;
            z-index:            2;
            box-shadow:         0 6px 12px rgba(0, 0, 0, 0.175);
		    background-clip:    padding-box;
        }
          
        .react-autosuggest__suggestions-list 
        {
            margin:             0;
            padding:            0;
            list-style-type:    none;
        }
        
        .react-autosuggest__suggestion 
        {
            cursor:     pointer;
            padding:    10px 20px;
        }
        
        .react-autosuggest__suggestion--highlighted 
        {
            background-color: $chefhero-hover-green;
        }
